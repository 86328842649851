import React, { useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';
import './mapChart.css';
const geoUrl =
  'https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json';
// 'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json';
// 'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-10m.json';
// './countries.json';
// 'https://github.com/deldersveld/topojson/blob/master/world-countries.json'
// 'https://s3.amazonaws.com/rawstore.datahub.io/23f420f929e0e09c39d916b8aaa166fb.geojson';

// const markers = [
//   {
//     markerOffset: -15,
//     name: `<h3> مصر </h3>
// 				<ul>
// 					<li>الموالح</li>
// 					<li>الرمان</li>
// 					<li>الفراوله</li>
// 				</ul>
// 		`,
//     coordinates: [30.0595581, 31.2234449],
//     type: 'grower',
//   },

//   {
//     markerOffset: -15,
//     name: `<h3>الهند </h3>
// 		<ul>
// 			<li>الموالح</li>
// 			<li>الرمان</li>
// 			<li>الفراوله</li>
// 		</ul>
// `,
//     coordinates: [76.9531797, 23.6471948],
//     type: 'grower',
//   },
//   {
//     markerOffset: -15,
//     name: `<h3> المغرب </h3>
// 		<ul>
// 			<li>العنب</li>
// 			<li>الرمان</li>
// 			<li>الفراوله</li>
// 		</ul>
// `,
//     coordinates: [-8.0778929, 31.6347485],
//     type: 'grower',
//   },

//   {
//     markerOffset: -15,
//     name: `<h3>الولايات المتحده الامريكية </h3>
// 		<ul>
// 			<li>الموالح</li>
// 			<li>الرمان</li>
// 			<li>الفراوله</li>
// 		</ul>
// `,
//     coordinates: [-88.0121478, 41.8333925],
//     type: 'customer',
//   },

//   {
//     markerOffset: -15,
//     name: `<h3>الامارات </h3>
// 		<ul>
// 			<li>الموالح</li>
// 			<li>الرمان</li>
// 			<li>الفراوله</li>
// 		</ul>
// `,
//     coordinates: [55.0876629, 25.0755935],
//     type: 'customer',
//   },
//   {
//     markerOffset: -15,
//     name: `<h3>روسيا </h3>
// 		<ul>
// 			<li>الموالح</li>
// 			<li>الرمان</li>
// 			<li>الفراوله</li>
// 		</ul>
// `,
//     coordinates: [80.8498354, 60.7653882],
//     type: 'customer',
//   },
//   {
//     markerOffset: -15,
//     name: `<h3>الصين </h3>
// 		<ul>
// 			<li>الموالح</li>
// 			<li>الرمان</li>
// 			<li>الفراوله</li>
// 		</ul>
// `,
//     coordinates: [95.0847134, 34.4197637],
//     type: 'customer',
//   },

//   {
//     markerOffset: -15,
//     name: `<h3>اندونيسيا </h3>
// 		<ul>
// 			<li>الموالح</li>
// 			<li>الرمان</li>
// 			<li>الفراوله</li>
// 		</ul>
// `,
//     coordinates: [108.8489346, -2.4153094],
//     type: 'customer',
//   },
// ];

const markers = [
  {
    markerOffset: -15,
    name: 'الولايات المتحده الامريكية',
    coordinates: [-88.0121478, 41.8333925],
    type: 'customer',
    engName: 'United States of America',
    labelOffset: { y: 15, x: -25 },
  },

  {
    markerOffset: -15,
    name: 'الامارات',
    coordinates: [55.0876629, 25.0755935],
    type: 'customer',
    engName: 'United Arab Emirates',
    labelOffset: { y: 0, x: 19 },
  },
  {
    markerOffset: -15,
    name: 'روسيا',
    coordinates: [80.8498354, 60.7653882],
    type: 'customer',
    engName: 'Russia',
    labelOffset: { y: 15, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'الصين',
    coordinates: [95.0847134, 34.4197637],
    type: 'customer',
    engName: 'China',
    labelOffset: { y: 8, x: 18 },
  },

  // {
  //   markerOffset: -15,
  //   name: 'اندونيسيا',
  //   coordinates: [108.8489346, -2.4153094],
  //   type: 'customer',
  //   engName: 'Indonesia',
  // },
  {
    markerOffset: -15,
    name: 'المانيا',
    coordinates: [11.4515, 53.1657],
    type: 'customer',
    engName: 'Germany',
    labelOffset: { y: -8, x: 10 },
  },
  {
    markerOffset: -15,
    name: 'بريطانيا',
    coordinates: [-3.4333, 53.55],
    type: 'customer',
    engName: 'United Kingdom',
    y: -12,
    labelOffset: { y: -10, x: -20 },
  },
  {
    markerOffset: -15,
    name: 'كندا',
    coordinates: [-106.3468, 56.1304],
    type: 'customer',
    engName: 'Canada',
    y: -9,
    labelOffset: { y: 14, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'فرنسا',
    coordinates: [2.2137, 46.2276],
    type: 'customer',
    engName: 'France',
    labelOffset: { y: 5, x: -20 },
  },
  {
    markerOffset: -15,
    name: 'بلجيكا',
    coordinates: [2, 51.5039],
    type: 'customer',
    engName: 'Belgium',
    labelOffset: { y: 2, x: -18 },
  },
  {
    markerOffset: -15,
    name: 'اسبانيا',
    coordinates: [-3.7492, 40.4637],
    type: 'customer',
    engName: 'Spain',
    labelOffset: { y: -5, x: -20 },
  },
  {
    markerOffset: -15,
    name: 'إيطاليا',
    coordinates: [12.5674, 41.8719],
    type: 'customer',
    engName: 'Italy',
    labelOffset: { y: 15, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'اليابان',
    coordinates: [138.2529, 36.2048],
    type: 'customer',
    engName: 'Japan',
    labelOffset: { y: 12, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'السعودية',
    coordinates: [45.0792, 23.8859],
    type: 'customer',
    engName: 'Saudi Arabia',
    y: 12,
    labelOffset: { y: 12, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'بولندا',
    coordinates: [19.1451, 51.9194],
    type: 'customer',
    engName: 'Poland',
    labelOffset: { y: 8, x: 20 },
  },
  {
    markerOffset: -15,
    name: 'سويسرا',
    coordinates: [8.2275, 46.8182],
    type: 'customer',
    engName: 'Switzerland',
    labelOffset: { y: 12, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'هولندا',
    coordinates: [5.2913, 52.1326],
    type: 'customer',
    engName: 'Netherlands',
    labelOffset: { y: -8, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'السويد',
    coordinates: [18.6435, 60.1282],
    type: 'customer',
    engName: 'Sweden',
    labelOffset: { y: 12, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'النمسا',
    coordinates: [14.5501, 47.5162],
    type: 'customer',
    engName: 'Austria',
    labelOffset: { y: -5, x: -13 },
  },
  {
    markerOffset: -15,
    name: 'المكسيك',
    coordinates: [-102.5528, 23.6345],
    type: 'customer',
    engName: 'Mexico',
    labelOffset: { y: 12, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'الكويت',
    coordinates: [46.4818, 31.3117],
    type: 'customer',
    engName: 'Kuwait',
    labelOffset: { y: 12, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'ماليزيا',
    coordinates: [101.9758, 4.2105],
    type: 'customer',
    engName: 'Malaysia',
    labelOffset: { y: 12, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'العراق',
    coordinates: [43.6793, 33.2232],
    type: 'customer',
    engName: 'Iraq',
    labelOffset: { y: 0, x: -18 },
  },
  {
    markerOffset: -15,
    name: 'اكرانيا',
    coordinates: [31.1656, 48.3794],
    type: 'customer',
    engName: 'Ukraine',
    labelOffset: { y: 0, x: 18 },
  },
  // {
  //   markerOffset: -15,
  //   name: 'رومانيا',
  //   coordinates: [24.9668, 45.9432],
  //   type: 'customer',
  //   engName: 'Romania',
  //   labelOffset: { y: 12, x: 2 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'برتغال',
  //   coordinates: [-8.2245, 39.3999],
  //   type: 'customer',
  //   engName: 'Portugal',
  //   labelOffset: { y: 0, x: -18 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'النرويج',
  //   coordinates: [8.4689, 60.472],
  //   type: 'customer',
  //   engName: 'Norway',
  //   labelOffset: { y: 12, x: 2 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'كوريا الجنوبية',
  //   coordinates: [127.7669, 35.9078],
  //   type: 'customer',
  //   engName: 'South Korea',
  //   labelOffset: { y: 12, x: 2 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'التشيك',
  //   coordinates: [15.473, 49.8175],
  //   type: 'customer',
  //   engName: 'Czechia',
  //   labelOffset: { y: 0, x: -10 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'بنغلاديش',
  //   coordinates: [90.3563, 23.685],
  //   type: 'customer',
  //   engName: 'Bangladesh',
  //   labelOffset: { y: 12, x: 2 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'أفغانستان',
  //   coordinates: [67.71, 33.9391],
  //   type: 'customer',
  //   engName: 'Afghanistan',
  //   labelOffset: { y: -9, x: 10 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'بيلاروسيا',
  //   coordinates: [27.9534, 53.7098],
  //   type: 'customer',
  //   engName: 'Belarus',
  //   labelOffset: { y: 12, x: 2 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'البرازيل',
  //   coordinates: [-51.9253, -14.235],
  //   type: 'customer',
  //   engName: 'Brazil',
  //   labelOffset: { y: 12, x: 2 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'ساحل العاج',
  //   coordinates: [-5.5471, 7.54],
  //   type: 'customer',
  //   engName: "Côte d'Ivoire",
  //   labelOffset: { y: 12, x: 2 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'الدنمارك',
  //   coordinates: [9.5018, 56.2639],
  //   type: 'customer',
  //   engName: 'Denmark',
  //   labelOffset: { y: -5, x: 2 },
  // },
  {
    markerOffset: -15,
    name: 'فنلندا',
    coordinates: [25.7482, 61.9241],
    type: 'customer',
    engName: 'Finland',
    labelOffset: { y: 12, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'اليونان',
    coordinates: [21.8243, 39.0742],
    type: 'customer',
    engName: 'greece',
    labelOffset: { y: 12, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'الهند',
    coordinates: [78.9629, 20.5937],
    type: 'customer',
    engName: 'India',
    labelOffset: { y: 12, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'إندونيسيا',
    coordinates: [113.9213, -0.7893],
    type: 'customer',
    engName: 'Indonesia',
    labelOffset: { y: 12, x: 2 },
  },
  // {
  //   markerOffset: -15,
  //   name: 'إيران',
  //   coordinates: [53.688, 32.4279],
  //   type: 'customer',
  //   engName: 'Iran',
  //   labelOffset: { y: 12, x: 2 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'أيرلندا',
  //   coordinates: [-7.6921, 53.1424],
  //   type: 'customer',
  //   engName: 'Ireland',
  //   labelOffset: { y: 12, x: 2 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'كازاخستان',
  //   coordinates: [66.9237, 48.0196],
  //   type: 'customer',
  //   engName: 'Kazakhstan',
  //   labelOffset: { y: 12, x: 2 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'نيبال',
  //   coordinates: [84.124, 28.3949],
  //   type: 'customer',
  //   engName: 'Nepal',
  //   labelOffset: { y: 12, x: 0 },
  // },
  {
    markerOffset: -15,
    name: 'المغرب',
    coordinates: [-7.0926, 31.7917],
    type: 'customer',
    engName: 'Morocco',
    labelOffset: { y: 12, x: 2 },
  },
  {
    markerOffset: -15,
    name: 'عمان',
    coordinates: [55.9754, 21.4735],
    type: 'customer',
    engName: 'Oman',
    labelOffset: { y: 12, x: 2 },
  },
  // {
  //   markerOffset: -15,
  //   name: 'باكستان',
  //   coordinates: [69.3451, 30.3753],
  //   type: 'customer',
  //   engName: 'Pakistan',
  //   labelOffset: { y: 12, x: 2 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'الفيلبن',
  //   coordinates: [121.774, 12.8797],
  //   type: 'customer',
  //   engName: 'Philippines',
  //   labelOffset: { y: 2, x: -18 },
  // },
  {
    markerOffset: -15,
    name: 'قطر',
    coordinates: [51.1839, 25.3548],
    type: 'customer',
    engName: 'Qatar',
    labelOffset: { y: -2, x: -10 },
  },
  // {
  //   markerOffset: -15,
  //   name: 'السنغال',
  //   coordinates: [-14.4524, 14.4974],
  //   type: 'customer',
  //   engName: 'Senegal',
  //   labelOffset: { y: -7, x: -10 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'سلوفاكيا',
  //   coordinates: [19.699, 48.669],
  //   type: 'customer',
  //   engName: 'Slovakia',
  //   labelOffset: { y: -5, x: 20 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'سيريلانكا',
  //   coordinates: [80.7718, 7.8731],
  //   type: 'customer',
  //   engName: 'Sri Lanka',
  //   labelOffset: { y: -5, x: 20 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'تايلاند',
  //   coordinates: [100.9925, 15.87],
  //   type: 'customer',
  //   engName: 'Thailand',
  //   labelOffset: { y: -5, x: 20 },
  // },
  // {
  //   markerOffset: -15,
  //   name: 'سلوفينيا',
  //   coordinates: [16.9955, 46.1512],
  //   type: 'customer',
  //   engName: 'Slovenia',
  //   labelOffset: { y: 10, x: -15 },
  // },
];

const wantedCountries = [
  // 'Morocco',
  'United Arab Emirates',
  // 'India',
  // 'Indonesia',
  'China',
  'United States of America',
  'Russia',
  'Germany',
  'United Kingdom',
  'Canada',
  'France',
  'Belgium',
  'Spain',
  'Italy',
  'Japan',
  'Saudi Arabia',
  'Poland',
  'Switzerland',
  'Netherlands',
  'Sweden',
  'Austria',
  'Mexico',
  'Kuwait',
  'Malaysia',
  'Iraq',
  'Ukraine',
  // 'Romania',
  // 'Portugal',
  // 'Norway',
  // 'South Korea',
  // 'Czechia',
  // 'Bangladesh',
  // 'Afghanistan',
  // 'Belarus',
  // 'Brazil',
  // "Côte d'Ivoire",
  // 'Denmark',
  'Finland',
  'greece',
  'India',
  'Indonesia',
  // 'Iran',
  // 'Ireland',
  // 'Kazakhstan',
  'Morocco',
  // 'Nepal',
  'Oman',
  // 'Pakistan',
  // 'Philippines',
  'Qatar',
  // 'Senegal',
  // 'Slovakia',
  // 'Slovenia',
  // 'Sri Lanka',
  // 'Thailand',
];

function hoverCountry(country) {
  return wantedCountries.includes(country);
}

export const MapChart = ({ setTooltipContent, setCountry }) => {
  const [selected, setSelected] = useState('Canada');
  const [hovered, setHovered] = useState('');

  return (
    <>
      <ComposableMap
        height={380}
        projection="geoMercator"
        projectionConfig={{
          // scale: 250,
          scale: 130,
          // scale: 300,
          // rotate: [-6, -60, 20],
          rotate: [0, 0, 0],
          // rotate: [-30, -60, 0],
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              // console.log(geo.properties.name);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={
                    selected === geo.properties.name
                      ? '#57709bb8'
                      : hovered === geo.properties.name
                      ? '#57709bb8'
                      : '#dddddd'
                  }
                  stroke="#ffffff"
                  strokeWidth={0.35}
                  style={{
                    hover: {
                      fill: hoverCountry(geo.properties.name)
                        ? '#57709bb8'
                        : '',
                      cursor: hoverCountry(geo.properties.name)
                        ? 'pointer'
                        : '',
                    },
                  }}
                  onClick={() => {
                    // console.log(geo.properties.name);
                    switch (true) {
                      // case geo.properties.name === 'Morocco':
                      //   setCountry('المغرب');
                      //   break;
                      case geo.properties.name === 'United Arab Emirates':
                        setCountry('الامارات');
                        setSelected('United Arab Emirates');
                        break;

                      case geo.properties.name === 'China':
                        setCountry('الصين');
                        setSelected('China');
                        break;
                      case geo.properties.name === 'United States of America':
                        setCountry('الولايات المتحده الامريكية');
                        setSelected('United States of America');
                        break;
                      case geo.properties.name === 'Russia':
                        setCountry('روسيا');
                        setSelected('Russia');
                        break;
                      case geo.properties.name === 'Germany':
                        setCountry('المانيا');
                        setSelected('Germany');
                        break;
                      case geo.properties.name === 'United Kingdom':
                        setCountry('بريطانيا');
                        setSelected('United Kingdom');
                        break;
                      case geo.properties.name === 'Canada':
                        setCountry('كندا');
                        setSelected('Canada');
                        break;
                      case geo.properties.name === 'France':
                        setCountry('فرنسا');
                        setSelected('France');
                        break;
                      case geo.properties.name === 'Belgium':
                        setCountry('بلجيكا');
                        setSelected('Belgium');
                        break;
                      case geo.properties.name === 'Spain':
                        setCountry('اسبانيا');
                        setSelected('Spain');
                        break;
                      case geo.properties.name === 'Italy':
                        setCountry('إيطاليا');
                        setSelected('Italy');
                        break;
                      case geo.properties.name === 'Japan':
                        setCountry('اليابان');
                        setSelected('Japan');
                        break;
                      case geo.properties.name === 'Saudi Arabia':
                        setCountry('السعودية');
                        setSelected('Saudi Arabia');
                        break;
                      case geo.properties.name === 'Poland':
                        setCountry('بولندا');
                        setSelected('Poland');
                        break;
                      case geo.properties.name === 'Switzerland':
                        setCountry('سويسرا');
                        setSelected('Switzerland');
                        break;
                      case geo.properties.name === 'Netherlands':
                        setCountry('هولندا');
                        setSelected('Netherlands');
                        break;
                      case geo.properties.name === 'Sweden':
                        setCountry('السويد');
                        setSelected('Sweden');
                        break;
                      case geo.properties.name === 'Austria':
                        setCountry('النمسا');
                        setSelected('Austria');
                        break;
                      case geo.properties.name === 'Mexico':
                        setCountry('المكسيك');
                        setSelected('Mexico');
                        break;
                      case geo.properties.name === 'Kuwait':
                        setCountry('الكويت');
                        setSelected('Kuwait');
                        break;
                      case geo.properties.name === 'Malaysia':
                        setCountry('ماليزيا');
                        setSelected('Malaysia');
                        break;
                      case geo.properties.name === 'Iraq':
                        setCountry('العراق');
                        setSelected('Iraq');
                        break;
                      case geo.properties.name === 'Ukraine':
                        setCountry('اكرانيا');
                        setSelected('Ukraine');
                        break;
                      // case geo.properties.name === 'Romania':
                      //   setCountry('رومانيا');
                      //   setSelected('Romania');
                      //   break;
                      // case geo.properties.name === 'Portugal':
                      //   setCountry('برتغال');
                      //   setSelected('Portugal');
                      //   break;
                      // case geo.properties.name === 'Norway':
                      //   setCountry('النرويج');
                      //   setSelected('Norway');
                      //   break;
                      // case geo.properties.name === 'South Korea':
                      //   setCountry('كوريا الجنوبية');
                      //   setSelected('South Korea');
                      //   break;
                      // case geo.properties.name === 'Czechia':
                      //   setCountry('التشيك');
                      //   setSelected('Czechia');
                      //   break;
                      // case geo.properties.name === 'Bangladesh':
                      //   setCountry('بنغلاديش');
                      //   setSelected('Bangladesh');
                      //   break;
                      // case geo.properties.name === 'Afghanistan':
                      //   setCountry('أفغانستان');
                      //   setSelected('Afghanistan');
                      //   break;
                      // case geo.properties.name === 'Belarus':
                      //   setCountry('بيلاروسيا');
                      //   setSelected('Belarus');
                      //   break;
                      // case geo.properties.name === 'Brazil':
                      //   setCountry('البرازيل');
                      //   setSelected('Brazil');
                      //   break;
                      // case geo.properties.name === "Côte d'Ivoire":
                      //   setCountry('ساحل العاج');
                      //   setSelected("Côte d'Ivoire");
                      //   break;
                      // case geo.properties.name === 'Denmark':
                      //   setCountry('الدنمارك');
                      //   setSelected('Denmark');
                      //   break;
                      case geo.properties.name === 'Finland':
                        setCountry('فنلندا');
                        setSelected('Finland');
                        break;
                      case geo.properties.name === 'greece':
                        setCountry('اليونان');
                        setSelected('greece');
                        break;
                      case geo.properties.name === 'India':
                        setCountry('الهند');
                        setSelected('India');
                        break;
                      case geo.properties.name === 'Indonesia':
                        setCountry('إندونيسيا');
                        setSelected('Indonesia');
                        break;
                      // case geo.properties.name === 'Iran':
                      //   setCountry('إيران');
                      //   setSelected('Iran');
                      //   break;
                      // case geo.properties.name === 'Ireland':
                      //   setCountry('أيرلندا');
                      //   setSelected('Ireland');
                      //   break;
                      // case geo.properties.name === 'Kazakhstan':
                      //   setCountry('كازاخستان');
                      //   setSelected('Kazakhstan');
                      //   break;
                      // case geo.properties.name === 'Nepal':
                      //   setCountry('نيبال');
                      //   setSelected('Nepal');
                      //   break;
                      case geo.properties.name === 'Morocco':
                        setCountry('المغرب');
                        setSelected('Morocco');
                        break;
                      case geo.properties.name === 'Oman':
                        setCountry('عمان');
                        setSelected('Oman');
                        break;
                      // case geo.properties.name === 'Pakistan':
                      //   setCountry('باكستان');
                      //   setSelected('Pakistan');
                      //   break;
                      // case geo.properties.name === 'Philippines':
                      //   setCountry('الفيلبن');
                      //   setSelected('Philippines');
                      //   break;
                      case geo.properties.name === 'Qatar':
                        setCountry('قطر');
                        setSelected('Qatar');
                        break;
                      // case geo.properties.name === 'Senegal':
                      //   setCountry('السنغال');
                      //   setSelected('Senegal');
                      //   break;
                      // case geo.properties.name === 'Slovakia':
                      //   setCountry('سلوفاكيا');
                      //   setSelected('Slovakia');
                      //   break;
                      // case geo.properties.name === 'Sri Lanka':
                      //   setCountry('سيريلانكا');
                      //   setSelected('Sri Lanka');
                      //   break;
                      // case geo.properties.name === 'Thailand':
                      //   setCountry('تايلاند');
                      //   setSelected('Thailand');
                      //   break;
                      // case geo.properties.name === 'Slovenia':
                      //   setCountry('سلوفينيا');
                      //   setSelected('Slovenia');
                      //   break;
                      default:
                    }
                  }}
                />
              );
            })
          }
        </Geographies>

        {markers.map(function ({
          name,
          coordinates,
          markerOffset,
          type,
          engName,
          labelOffset,
        }) {
          var color = '#3f9f42';
          return (
            <Marker
              key={name}
              coordinates={coordinates}
              data-tip=""
              onMouseEnter={() => {
                setTooltipContent(`${name}`);
                setHovered(engName);
              }}
              onMouseLeave={() => {
                setTooltipContent('');
                setHovered('');
              }}
              onClick={() => {
                setCountry(`${name}`);
                setSelected(engName);
              }}
            >
              <circle r={4} fill={color} stroke="#fff" strokeWidth={0.2}>
                <animate
                  attributeName="r"
                  from="4"
                  to="8"
                  dur="1.5s"
                  begin="0s"
                  repeatCount="indefinite"
                />
                <animate
                  attributeName="opacity"
                  from="1"
                  to="0"
                  dur="1.5s"
                  begin="0s"
                  repeatCount="indefinite"
                />
              </circle>
              <circle r={3} fill="#ffffff" stroke={color} strokeWidth={1.7} />
              {selected === engName ? (
                <text
                  y={labelOffset.y}
                  x={labelOffset.x}
                  fontSize={10}
                  textAnchor="middle"
                >
                  {name}
                </text>
              ) : (
                ''
              )}
            </Marker>
          );
        })}
      </ComposableMap>
    </>
  );
};
